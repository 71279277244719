import React from 'react'
import './App.css'
// import { BrowserRouter as Router, Route } from "react-router-dom"
// import PhotoPage from './photo-page/PhotoPage'
// import EditPage from './edit-page/EditPage'
// import SharePage from './share-page/SharePage'
// import RecapPage from './recap-page/RecapPage'
import LandingPage from './landing-page/LandingPage'

// import TestPage from './test-page/TestPage'


function App() {
  return (
    <LandingPage />
  )
  // return (
  //   <Router>
  //     <Route exact path='/' component={PhotoPage} />
      
  //     <Route path='/edit' render={props => <EditPage {...props} />} />
  //     <Route path='/share' render={props => <SharePage {...props} />} />
  //     <Route path='/recap' render={props => <RecapPage {...props} />} />

  //     <Route path='/test' component={PhotoPage} />

  //     {/* <Route exact path='/test' component={TestPage} /> */}
  //   </Router>
  // )
}

export default App