import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "landingPage": {
        "slogan": "Easily share the bill with your friends",
        "subSlogan": "No more headaches while paying the bill!",
        "how": "How does it work?",
        "how1": "Take a photo of the bill, the app analyzes the photo and extracts all the elements",
        "how2": "Add people from your table",
        "how3": "Select what each person has eaten and drunk, it is possible to share a dish between several people",
        "how4": "The app displays the total per person, everyone can now pay or refund their share",
        "contact": "Contact us",
        "linkedin": "Follow us on LinkedIn"
      },
      "photoPage": {
        "takePhoto": "Take a photo",
        "description": "Load a photo of the bill and easily share the total amount with your friends.",
        "extracting": "Extracting data",
        "errorOcr": "Something went wrong extracting bill data. Upload a new photo!",
        "errorConnection": "Something went wrong uploading the photo. Check your connection!",
        "retry": "Retry",
        "iosBeta": "The iOS app is available in beta testing through TestFlight!",
        "feedback": "Don't hesitate to send us your feedback!",
        "downloadApp": "Download the app"
      },
      "sharePage": {
        "remaining": "Remaining",
        "recap": "Recap"
      },
      "footer": {
        "enterName": "Enter a new name:",
        "confirmDelete": "Are you sure you want to delete {{name}}?"
      },
      "editPage": {
        "title": "Edit, add or remove items",
        "footer": "No error? Ready to share!",
        "quantity": "Quantity",
        "name": "Name",
        "price": "Price",
        "confirmDelete": "Are you sure you want to delete this element '{{name}}'?",
        "cancel": "Cancel",
        "save": "Save"
      }
    }
  },
  es: {
    translation: {
      "landingPage": {
        "slogan": "Comparte fácilmente la cuenta con tus amigos",
        "subSlogan": "¡Olvídate de líos a la hora de pagar la cuenta!",
        "how": "¿Cómo funciona?",
        "how1": "Haz una foto de la cuenta, la aplicación la analiza y extrae todos los elementos",
        "how2": "Agrega a las personas de la mesa",
        "how3": "Selecciona lo que ha consumido cada uno, es posible compartir un plato entre varias personas",
        "how4": "La aplicación muestra el total por persona, ahora cada uno puede pagar o reembolsar su parte",
        "contact": "Contáctanos",
        "linkedin": "Síguenos en LinkedIn"
      },
      "photoPage": {
        "takePhoto": "Tomar una foto",
        "description": "Carga una foto de la cuenta y comparte simplemente el importe total con tus amigos.",
        "extracting": "Extrayendo los datos",
        "errorOcr": "Algo salió mal extrayendo los datos. ¡Carga una nueva foto!",
        "errorConnection": "Algo salió mal cargando la foto. ¡Revisa tu conexión!",
        "retry": "Volver a probar",
        "iosBeta": "¡La app iOS está disponible beta testing a través de TestFlight!",
        "feedback": "¡No dudes en enviarnos tus comentarios!",
        "downloadApp": "Descargar la app"
      },
      "sharePage": {
        "remaining": "Resto",
        "recap": "Recap"
      },
      "footer": {
        "enterName": "Ingrese un nuevo nombre:",
        "confirmDelete": "¿Estás seguro de que quieres eliminar {{name}}?"
      },
      "editPage": {
        "title": "Agrega, edita o elimina elementos",
        "footer": "¿No hay error? ¡Listo para compartir!",
        "quantity": "Quantidad",
        "name": "Nombre",
        "price": "Precio",
        "confirmDelete": "¿Estás seguro de que quieres eliminar este elemento '{{name}}'?",
        "cancel": "Cancelar",
        "save": "Guardar"
      }
    }
  },
  fr: {
    translation: {
      "landingPage": {
        "slogan": "Partage facilement l'addition avec tes amis",
        "subSlogan": "Fini les prises de tête au moment de payer l'addition !",
        "how": "Comment ça marche ?",
        "how1": "Prends en photo l'addition, l'appli analyse la photo et extrait tous les éléments",
        "how2": "Ajoute les personnes de ta table",
        "how3": "Sélectionne ce que chacun a consommé, il est possible de partager un plat entre plusieurs personnes",
        "how4": "L'application affiche le total par personne, chacun peut maintenant payer ou rembourser sa part",
        "contact": "Contacte-nous",
        "linkedin": "Suis-nous sur LinkedIn"
      },
      "photoPage": {
        "takePhoto": "Prendre une photo",
        "description": "Envoie une photo de l'addition et partage facilement le montant total avec tes amis.",
        "extracting": "Extraction des données",
        "errorOcr": "Une erreur s'est produite lors de l'extraction des données. Envoie une nouvelle photo !",
        "errorConnection": "Une erreur s'est produite lors du chargement de la photo. Vérifie ta connexion !",
        "retry": "Ressayer",
        "iosBeta": "L'appli iOS est disponible en version beta via TestFlight !",
        "feedback": "N'hésite pas à nous faire part de tes retours !",
        "downloadApp": "Télécharger l'appli"
      },
      "sharePage": {
        "remaining": "Restant",
        "recap": "Récap"
      },
      "footer": {
        "enterName": "Entre un nouveau nom :",
        "confirmDelete": "Es-tu sûr de vouloir supprimer {{name}} ?"
      },
      "editPage": {
        "title": "Ajoute, modifie ou supprime des éléments",
        "footer": "Pas d'erreur ? Prêt à partager !",
        "quantity": "Quantité",
        "name": "Nom",
        "price": "Prix",
        "confirmDelete": "Es-tu sûr de vouloir supprimer cet élément '{{name}}' ?",
        "cancel": "Annuler",
        "save": "Enregistrer"
      }
    }
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

  export default i18n