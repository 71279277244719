import React, { createRef } from 'react'
import { withTranslation } from 'react-i18next'
import './LandingPage.css'

import logo from '../images/logo.png'
import logoIos from '../images/download_ios.svg'
import logoAndroid from '../images/download_android.png'

import preview from '../videos/preview.mov'

class LandingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.videoRef = createRef()
  }

  componentDidMount() {
    this.videoRef.current.defaultMuted = true
    this.videoRef.current.muted = true
    this.videoRef.current.loop = true
    this.videoRef.current.controls = true
    this.videoRef.current.autoplay = true
  }

  render() {
    
    const { t } = this.props

    return(
      <main>
        <div className='container'>
          <div className='logoText'>
            <img src={logo} alt="logo" className='logo' width={50} height={50} />
            <h1>La Cuentax</h1>
          </div>
          <div className='grid'>
            <div className='row'>
              <div className='col-md-8'>
                <div className='slogan'>{t('landingPage.slogan')}</div>
                <div className='subSlogan'>{t('landingPage.subSlogan')}</div>
                <div className='buttons'>
                  <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.lacuentax">
                    <img src={logoAndroid} alt="download_android" className="downloadButton" />
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://apps.apple.com/es/app/la-cuentax/id1607113383">
                    <img src={logoIos} alt="download_ios" className="downloadButton" />
                  </a>
                </div>
                <div className='explication'>
                  <div className='how'>{t('landingPage.how')}</div><br />
                  <ul>
                    <li>{t('landingPage.how1')}</li><br />
                    <li>{t('landingPage.how2')}</li><br />
                    <li>{t('landingPage.how3')}</li><br />
                    <li>{t('landingPage.how4')}</li>
                  </ul>
                </div>
              </div>
              <div className='col-md-4 colVideo'>
                <video ref={this.videoRef} preload="metadata" playsInline>
                  <source src={preview + '#t=15'} />
                </video>
              </div>
            </div>
          </div>
          <div className='footer'>
            <hr />
            <div className='grid row footerTexts'>
              <div className='col-md'>
                <a href="mailto:contact@lacuentax.com">
                  {t('landingPage.contact')}
                </a>
              </div>
              <div className='col-md'>
                <a href="https://www.linkedin.com/company/la-cuentax" target="_blank" rel="noreferrer">
                  {t('landingPage.linkedin')}
                </a>
              </div>
              <div className='col-md'>
                © La Cuentax, 2023
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default withTranslation()(LandingPage)